import { useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";

// styles
import "react-alice-carousel/lib/alice-carousel.css";
import classes from "./BannerCarousel.module.scss";

// component
import AliceCarousel from "react-alice-carousel";

function BannerCarousel({ bannerList = [], loading, setLoading }) {
  const router = useRouter();
  const [paddingTop, setPaddingTop] = useState(0);

  const items = bannerList?.map((data, index) => {
    const imageDisplay = process.env.BUCKET_URL + data.homeImage;

    return (
      <div
        className="relative max-h-[67vh] rounded-20 w-full cursor-pointer"
        style={{ paddingTop }}
        key={index}
      >
        <Image
          src={imageDisplay}
          alt={data.title}
          layout="fill"
          objectFit="cover"
          onLoad={(e) => {
            setPaddingTop(`calc(100% / 2.56`);
            setLoading(false);
          }}
          onClick={() => {
            router.push({
              pathname: `/${data.seoUrl}`,
              state: {
                seoUrl: data.seoUrl,
              },
            });
          }}
        />
      </div>
    );
  });

  return (
    <div className={classes.container}>
      {items?.length > 0 && (
        <AliceCarousel
          autoHeight
          items={items}
          autoPlayInterval={3000}
          autoPlay
          infinite
        />
      )}
    </div>
  );
}

export default BannerCarousel;
